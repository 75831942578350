@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500;800&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $teal_blue;
  color: $cultured;
  font-size: 22px;
  line-height: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 80px;
  line-height: 120px;
}

header {
  font-size: 58px;
  line-height: 51px;
  font-family: 'Alegreya Sans', sans-serif;
}
