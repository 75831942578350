@import "./colors.scss";
@import "./typography.scss";

body {
  margin: 0;
  background-color: $teal_blue;
  color: $cultured; 
}


