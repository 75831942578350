.FrontPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    max-width: 600px;
  }
}

.summary {
  text-align: center;
  margin-bottom: 60px;
}
