@import './css/colors.scss';
@import './css/typography.scss';

.logo {
  font-size: 80px;
  line-height: 120px;
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 800;
  margin-bottom: 0;
}
